import React from "react"
import { parseISO } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Asset(s) */
import "./event-header.scss"

const EventHeader = ({ location, event: { dateFrom, title } }) => {
  const prevUrlPath = location.state?.prevUrlPath

  // Uses 1.29 of date-fns formatting
  const formatWithTimezone = (date, displayFormat) => {
    const storedDate = new Date(date)
    const timeZone = "America/New_York"
    const displayedTime = formatToTimeZone(storedDate, displayFormat, {
      timeZone,
    })
    return `${displayedTime}`
  }

  return (
    <header className="event-header">
      {dateFrom && <p className="date">{`${formatWithTimezone(
        parseISO(dateFrom),
        "D MMM"
      )}`}</p>}
      <Link to={prevUrlPath ? prevUrlPath : "/program"} className="close">
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>
      <h1>{title && <span>{title}</span>}</h1>
    </header>
  )
}

export default EventHeader
