import React, { useEffect, useState, useContext } from "react"
import { useCookies } from 'react-cookie';
import classNames from "classnames"
import { parseISO, getMinutes } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"
import { navigate } from "gatsby"
import slug from "slug"

import axios from "axios"

// import { marked } from "marked"

import DayModal from '~components/pages/program/day/dayModal'

import CrossSvgSrc from "~assets/images/close.svg"

/* Import Global Animation(s) */
import { motion, AnimatePresence } from "framer-motion"
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Context(s) */
import TokenContext from "~context/token"
import AccountContext from "~context/account"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Image from "~components/image/image"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Card from "~components/card/card"

/* Import Local Components */
import EventHeader from "~components/pages/program/event/eventHeader"

/* Import Local Style(s) */
import "./event.scss"


const Event = ({ location, params }) => {

  const programEvent = null;
  const vip = null;
  const rsvpUrl = null;

  const [tokenString, setToken] = useContext(TokenContext);
  const [cookies, setCookie] = useCookies(['sessionToken']);
  const [accountInfo, setAccountInfo] = useContext(AccountContext);

  const [thisEvent, setEvent] = useState([])
  const [successfulRequest, setSuccessfulRequest] = useState(false)
  const [requestMessage, setRequestMessage] = useState('Successful Registration')

  const [showModal, setShowModal] = useState(false)


  // Uses 1.29 of date-fns formatting
  const formatWithTimezone = (date, displayFormat) => {
    const storedDate = new Date(date)

    const timeZone =  "Africa/Abidjan" //"America/New_York"
    const displayedTime = formatToTimeZone(storedDate, displayFormat, {
      timeZone,
    })
    return `${displayedTime}`
  }

  const generateEventTime = (startDatetime, endDatetime) => {
    const startDate = startDatetime

    if(!startDatetime){ return '' }

    if (endDatetime) {
      const endDate = endDatetime
      if (
        formatWithTimezone(startDate, "a") !== formatWithTimezone(endDate, "a")
      ) {
        return `${
          getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "ha")
            : formatWithTimezone(startDate, "h:mma")
        }–${
          getMinutes(parseISO(endDate)) === 0
            ? formatWithTimezone(endDate, "ha")
            : formatWithTimezone(endDate, "h:mma")
        }`
      } else {

        // has endtime but times are identical
        if(formatWithTimezone(startDate, "h:mm") == formatWithTimezone(endDate, "h:mm")){
          return getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "ha")
            : formatWithTimezone(startDate, "h:mma")
        }

        // if not identical
        return `${
          getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "h")
            : formatWithTimezone(startDate, "h:mm")
        }–${
          getMinutes(parseISO(endDate)) === 0
            ? formatWithTimezone(endDate, "ha")
            : formatWithTimezone(endDate, "h:mma")
        }`
      }
    } else {
      return getMinutes(parseISO(startDate)) === 0
        ? formatWithTimezone(startDate, "ha")
        : formatWithTimezone(startDate, "h:mma")
    }
  }


  const requestEvent = () => {
    const method = (cookies.sessionToken) ? 'with_auth' : 'get'
    const contents = (cookies.sessionToken) ? { token: cookies.sessionToken } : {}

    axios
    .post("/.netlify/functions/vipUsers", {
      contents: contents,
      method: method,
      path: "/events"
    })
    .then(res => {
      console.log(res.data.data.data)

      console.log(location)
      console.log(params)

      const events = res.data.data.data.filter(d => slug(d.title) == params.event ) 

      if(events.length > 0){
        setEvent(events[0])
      }else{
        console.log('no event viable')
      }

    })
    .catch(e => {
      console.error(e)
    })
  }


  useEffect(() => {

    const method = (cookies.sessionToken) ? 'with_auth' : 'get'
    const contents = (cookies.sessionToken) ? { token: cookies.sessionToken } : {}

    axios
    .post("/.netlify/functions/vipUsers", {
      contents: contents,
      method: method,
      path: "/events"
    })
    .then(res => {
      console.log(res.data.data.data)

      console.log(location)
      console.log(params)

      const events = res.data.data.data.filter(d => `${slug(d.title)}_${formatWithTimezone(d.dateFrom, "D-M-YYYY")}` == params.event ) 

      if(events.length > 0){
        setEvent(events[0])
      }else{
        console.log('no event viable')
      }

    })
    .catch(e => {
      console.error(e)
    })


    if(cookies.sessionToken && !accountInfo){
      axios
      .post('/.netlify/functions/vipUsers', {
        contents: {token: cookies.sessionToken},
        method: 'with_auth',
        path: '/vips/auth/me'
      })
      .then(result => {
        // console.log(result)
        if(result.data.data !== undefined && result.data.data !== null){
          setAccountInfo(result.data.data)
        }else{
          console.log('unauthorized')
        }

      })
    }


  }, [axios, cookies, setEvent])



  if(thisEvent && thisEvent.isVip && !accountInfo && !cookies.sessionToken){
    if (typeof window !== `undefined`) {
      navigate("/")
      return null
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }


  const setupRSVP = ({
      isGuest = false,
      guest_firstname = null,
      guest_lastname = null,
      guest_email = null
    } = {}) => {

    console.log(isGuest, guest_firstname, guest_lastname, guest_email)

    if(isGuest){
      // submitting with +1

      onRSVP({
        rsvp: thisEvent.rsvp,
        links: thisEvent.links,
        guest_firstname,
        guest_lastname,
        guest_email
      })

    }else{
      // submitting alone

      onRSVP({
        rsvp: thisEvent.rsvp,
        links: thisEvent.links
      })

    }

  }


  const cancelRSVP = () => {
    onRSVP({
      rsvp: thisEvent.rsvp,
      links: thisEvent.links
    })
  }

  const onRSVP = async ({
      rsvp, 
      links,
      guest_lastname = null,
      guest_firstname = null,
      guest_email = null
    } = {}) => {



    if(rsvp.canRsvp){
      // can rsvp

      let body = {
        method: (rsvp !== null && rsvp.current && (rsvp.current.status == 'approved' || rsvp.current.status == 'waitinglist')) ? 'cancel_rsvp' : 'rsvp',
        contents: { token: cookies.sessionToken },
        path: links.rsvp
      }

      if(body.method == 'cancel_rsvp'){
        setRequestMessage('Successful Deregistration')
      }else if(body.method == 'rsvp'){
        setRequestMessage('Successful Registration')
      }

      if(guest_lastname){
        body.guest = { guest_lastname, guest_firstname, guest_email }
      }

      axios
      .post("/.netlify/functions/vipUsers", body)
      .then(res => {
        console.log(res)

        if(res.data){

          closeModal()

          setSuccessfulRequest(true)
          setTimeout(() => {
            requestEvent()
          }, 3000)

          setTimeout(() => {
            setSuccessfulRequest(false)
          }, 6000)
        }

      })
      .catch(error => {

        closeModal()

      })


    }else if(rsvp.canRsvp && rsvp.current && (rsvp.current.status == 'approved' || rsvp.current.status == 'waitinglist')){



      const body = {
        method: 'cancel_rsvp',
        contents: { token: cookies.sessionToken },
        path: links.rsvp
      }

      axios
      .post("/.netlify/functions/vipUsers", body)
      .then(res => {
        console.log(res)

        if(res.data){


          requestEvent()
        }
      })


    }else{
      console.log('no rsvp')
    }
  }



  return (
    <Page title="Event" location={location} className="template">
      {thisEvent && <EventHeader event={thisEvent} location={location} />}
      <PageArticle title="Event" className="event">
        <PageArticleSection className="event">
          
          { thisEvent && thisEvent.image && <img 
            className='mb--1' 
            src={!(thisEvent.image.original.includes('.gif')) ? thisEvent.image.medium : thisEvent.image.original } 
          /> }

          {thisEvent.description && <div>
            <div dangerouslySetInnerHTML={{ __html: thisEvent.description }}></div>
          </div>}

          {thisEvent.isVip && thisEvent.links && thisEvent.rsvp !== null && thisEvent.links.rsvp !== null && (thisEvent.rsvp && thisEvent.rsvp.canRsvp) && (
            <button 
              onClick={() => {
                // onRSVP(thisEvent.rsvp, thisEvent.links)
                if(thisEvent.rsvp?.current?.status == 'approved' || thisEvent.rsvp?.current?.status == 'waitinglist'){
                  cancelRSVP()
                }else{
                  setShowModal(true)
                }

              }}
              disabled={successfulRequest ? 'disabled' : false }
            >
              <Card
                backgroundColor={"var(--color-lightest-gray)"}
                className="program-event"
              >
                { !successfulRequest 
                  ? ((thisEvent.rsvp && thisEvent.rsvp.current && (thisEvent.rsvp.current.status == 'approved' || thisEvent.rsvp.current.status == "waitinglist")) 
                    ? (thisEvent.rsvp.current.status == 'approved') ? 'Cancel Registration' : 'Leave Waitlist'
                    : (thisEvent.rsvp && thisEvent.rsvp.isAtCapacity) ? 'Join Waitlist' : 'RSVP') 
                  : requestMessage
                }
              </Card>
            </button>
          )}

          {thisEvent?.isVip && thisEvent.rsvp?.current?.guest && (thisEvent.rsvp?.current?.status == 'approved' || thisEvent.rsvp?.current?.status == "waitinglist") && <div className="mt--1_2 center dark_gray">
            <p>with {thisEvent.rsvp?.current?.guest.firstname} {thisEvent.rsvp?.current?.guest.lastname}</p>
          </div>}

          <div className="mt--1 location">
            {(thisEvent.dateFrom || thisEvent.startDatetime || thisEvent.dateTo || thisEvent.endDatetime) && <p>{formatWithTimezone(
                          parseISO(thisEvent.dateFrom),
                          "D MMM, "
                        )} {generateEventTime((thisEvent.dateFrom !== undefined) ? thisEvent.dateFrom : thisEvent.startDatetime, (thisEvent.dateTo !== undefined) ? thisEvent.dateTo : thisEvent.endDatetime)}</p>}
            {thisEvent.location && <p>{thisEvent.location}</p>}
          </div>


          {showModal && <AnimatePresence exitBeforeEnter>
            <motion.div
              key="daymodal"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            >
            <div className="day-modal--modal">
              <button className="day-modal_close" onClick={closeModal}>
                <img
                  src={CrossSvgSrc}
                  className="close"
                  alt="cross"
                  key="cross"
                  exit="exit"
                />
              </button>
                <DayModal event={thisEvent} handleClick={setupRSVP} />          
              </div>
            </motion.div>
            
          </AnimatePresence>}


        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default Event
